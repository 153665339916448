import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import BelgianItsmeJwtSnippet from '../../../snippets/jwt-payloads/belgian-itsme';
export const _frontmatter = {
  "product": "verify",
  "category": "eIDs",
  "sort": 7,
  "title": "ItsME",
  "subtitle": "Learn more about ItsME token contents, how to create test users and how to gain access to production."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`JWT/Token example`}</h2>
    <p>{`Basic level, with Extra ID Data and Security Data enabled`}</p>
    <BelgianItsmeJwtSnippet mdxType="BelgianItsmeJwtSnippet" />
    <h2>{`Test users`}</h2>
    <p>{`You will need to enroll with the Itsme organization to get access to test accounts, which can be used in the `}<inlineCode parentName="p">{`Itsme Sandbox`}</inlineCode>{` environment.`}</p>
    <p>{`The process is `}<a parentName="p" {...{
        "href": "https://brand.belgianmobileid.be/d/CX5YsAKEmVI7/let-s-get-started#/step-by-step-guide/4-test-itsme-in-your-application"
      }}>{`described here`}</a>{` - just note that the integration guidelines of that documentation is not relevant for you, as you are integrating via Criipto's pre-built Itsme integration. It is all just a matter of configuring your project settings in our management UI.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      